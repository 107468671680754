import { createContext, useContext, useState, ReactNode } from "react";

export interface User {
  fullName?: string;
  emailAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  video?: File;
  public?: boolean;
  tokenId?: string;
  price?: string;
  message?: string;
}

type UserContextType = {
  user: User;
  fillUser: (user: User) => void;
};

const UserContext = createContext<UserContextType | null>(null);

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User>({ country: "FR" });

  const fillUser = (newUser: User) => {
    setUser((user) => ({ ...user, ...newUser }));
  };

  return <UserContext.Provider value={{ user, fillUser }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used inside a UserContext Provider");
  }
  return context;
};
