import { CardContainer } from "../../components/home/card-container";
import { InfoSlider } from "../../components/home/info-slider";
import { ITEMS } from "../../constants/items.constants";

export const HomePage = () => {
  return (
    <div className="relative flex h-fit w-full flex-col items-center gap-[75px] pb-[105px] pt-[70px]">
      <div className="absolute left-0 top-0 z-0 flex h-full w-full flex-col justify-between">
        <div className="z-0 flex h-[1080px] w-full bg-background-top bg-cover bg-center" />
        <div className="z-0 flex h-[1080px] w-full rotate-180 bg-background-bot bg-cover bg-center" />
      </div>
      <InfoSlider />
      <CardContainer cards={ITEMS} />
    </div>
  );
};
