import { Slider } from "../slider/slider";

import { ITEMS } from "../../constants/items.constants";

export const InfoSlider = () => {
  return (
    <div className="flex h-fit w-fit px-[10px] xl:px-[100px]">
      <div className="flex rounded-[10px] bg-white/50 px-[15px] pb-[86px] pt-[25px] backdrop-blur-[25px] xl:px-[38px] xl:pt-[104px]">
        <div className="flex w-fit flex-col gap-[50px] xl:flex-row">
          <div className="flex h-fit w-[90vw] flex-col flex-wrap gap-[45px] xl:w-[650px]">
            <span className="break-words text-heading3 uppercase text-darkGreen xl:break-normal xl:text-heading2">
              Devenez un père Noël vert !
            </span>
            <p className="w-auto break-words text-20-reg xl:w-[552px] xl:break-normal">
              Avec le Secours populaire, donnez un coup de main au Père Noël !<br />
              En décembre, les Pères Noël verts, parés aux couleurs de l’espérance, viennent en aide au légendaire Père
              Noël rouge.
            </p>
          </div>
          <div className="hidden xl:block">
            <Slider items={ITEMS} />
          </div>
        </div>
      </div>
    </div>
  );
};
