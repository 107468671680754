import Amount1 from "../assets/items/montant1-1.png";
import Amount2 from "../assets/items/montant1-2.png";
import Amount3 from "../assets/items/montant2-1.png";
import Amount4 from "../assets/items/montant2-2.png";
import Amount5 from "../assets/items/montant3-1.png";
import Amount6 from "../assets/items/montant3-2.png";
import Amount7 from "../assets/items/montant4-1.png";
import Amount8 from "../assets/items/montant4-2.png";
import Amount9 from "../assets/items/montant4-3.png";
import Amount10 from "../assets/items/montant5-1.png";
import Amount11 from "../assets/items/montant5-2.png";

export type Item = {
  id: number;
  title: string;
  picture: string;
  price?: string;
  description: string;
};

export const ITEMS: Item[] = [
  {
    id: 1,
    title: "Green Heart Santa",
    picture: Amount1,
    price: "50",
    description:
      "Description : Père Noël futuriste connectant les cœurs du web3 \nStyle : Père Noël avec un manteau technologique vert. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Bienvenue dans la brigade des Pères Noël Verts",
  },
  {
    id: 2,
    title: "Solar Green Santa",
    picture: Amount2,
    price: "50",
    description:
      "Description : Père Noël Web3 entouré de lumières scintillantes, symbole de solidarité. \nStyle : Père Noël dans une exosquelette lumineuse verte. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Bienvenue dans la brigade solaire des Pères Noël Verts",
  },
  {
    id: 3,
    title: "Green Joy Santa",
    picture: Amount3,
    price: "100",
    description:
      "Description : Père Noël futuriste créant une explosion de joie numérique. \nStyle : Père Noël avec une armure énergétique verte. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Bienvenue dans la compagnie des Pères Noël Verts !",
  },
  {
    id: 4,
    title: "Green Love Santa",
    picture: Amount4,
    price: "100",
    description:
      "Description : Père Noël Web3 laissant un sillage d'amour partout où il passe. \nStyle : Père Noël avec une cape numérique verte. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Bienvenue chez les Pères Noël Verts du Web3 !",
  },
  {
    id: 5,
    title: "Green Glow Santa",
    picture: Amount5,
    price: "200",
    description:
      "Description : Père Noël futuriste projetant des éclats de bienfaisance depuis son sceptre numérique. \nStyle : Père Noël en armure lumineuse verte. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Vous êtes officiellement un Père Noël Verts du web3",
  },
  {
    id: 6,
    title: "Green Hope Santa",
    picture: Amount6,
    price: "200",
    description:
      "Description : Père Noël futuriste regardant vers un horizon numérique d'espoir. \nStyle : Père Noël avec un casque de réalité virtuelle vert. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Votre vision apporte espoir et joie. Merci de faire partie des Pères Noël Verts !",
  },
  {
    id: 7,
    title: "Green Galaxy Santa",
    picture: Amount10,
    price: undefined,
    description:
      "Description : Père Noël futuriste naviguant dans une galaxie de générosité. \nStyle : Père Noël en combinaison spatiale verte avec des étoiles numériques. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Bienvenue dans la brigade galactique des Pères Noël Verts du Web3",
  },
  {
    id: 8,
    title: "Green Memory Santa",
    picture: Amount11,
    price: undefined,
    description:
      "Description : Père Noël Web3 qui immortalise les dons. \nStyle : Père Noël avec un appareil photo futuriste vert. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciement : Vous entrez dans l’album des Pères Noël Verts du web3 !",
  },
  {
    id: 9,
    title: "Green Tornado Santa",
    picture: Amount7,
    price: "500",
    description:
      "Description : Père Noël Web3 créant une tornade de générosité. \nStyle : Père Noël en combinaison spatiale verte futuriste. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciements : Bienvenue chez les puissants Pères Noël Verts du web3",
  },
  {
    id: 10,
    title: "Magnificent Green Santa",
    picture: Amount8,
    price: "500",
    description:
      "Description : Père Noël futuriste avec une aura digitale de solidarité \nStyle : Père Noël vêtu d'une tenue verte high-tech. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciements : Vous entrez officiellement dans la compagnie des épiques Pères Noël Verts du web3 !",
  },
  {
    id: 11,
    title: "Green Blow Santa",
    picture: Amount9,
    price: "500",
    description:
      "Description : Père Noël futuriste soufflant un vent de générosité \nStyle : Père Noël avec un équipement vert high-tech. \nCollection : Opération Père Noël Vert 2023. \nCréateur : Secours Populaire / Metacard \nRemerciements : Vous rejoignez les légendaires Pères Noël Verts du web3 !",
  },
];
