import { Item } from "../../constants/items.constants";

interface Props {
  size: "small" | "medium" | "large";
  item: Item;
}

export const SliderCard = ({ size, item }: Props) => {
  const TIMER = "12h  43m  42s";
  const sizeClass =
    size === "small" ? "h-[340px] w-[310px]" : size === "medium" ? "h-[390px] w-[355px]" : "h-[440px] w-[400px]";
  return (
    <div className="relative flex h-fit w-fit cursor-pointer rounded-[24px] transition-shadow hover:shadow-xl">
      <img className={`${sizeClass} rounded-[24px]`} src={item.picture} alt="icon" />
      <div className="absolute bottom-[23px] left-0 w-full px-[26px]">
        <div className="flex justify-between rounded-[12px] bg-dark/20 px-[26px] py-[14px] backdrop-blur-[25px]">
          <div className="flex flex-col gap-[7px]">
            <span className="text-12-med text-white">Prix</span>
            <span className="text-16-semi text-white">{item.price ? `${item.price} €` : "Prix libre"}</span>
          </div>
          <div className="flex flex-col gap-[7px]">
            <span className="text-12-med text-white">Ends in</span>
            <span className="text-16-semi text-white">{TIMER}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
