import DiscordLogo from "../assets/discord-color.svg";
import Gift from "../assets/gift.png";
import InstagramLogo from "../assets/instagram-color.svg";
import LinkedinLogo from "../assets/linkedin-color.svg";
import TwitterLogo from "../assets/twitter-color.svg";

const SOCIAL_MEDIA = [
  {
    logo: LinkedinLogo,
    link: "https://www.linkedin.com/",
  },
  {
    logo: InstagramLogo,
    link: "https://www.instagram.com/",
  },
  {
    logo: DiscordLogo,
    link: "https://discord.gg/",
  },
  {
    logo: TwitterLogo,
    link: "https://twitter.com/",
  },
];

export const ThankYouPage = () => {
  return (
    <div className="relative flex flex-col px-[26px] pb-[48px] pt-[20px]">
      <div className="flex flex-col gap-[50px]">
        <div className="flex flex-col gap-[22px]">
          <span className="text-32-semi text-darkGreen">Votre don est prêt !</span>
          <span className="text-16-reg text-blue">
            Votre NFT sera minté sous peu sur le portefeuille de paiement (Blockchain Polygon)
          </span>
        </div>
        <div className="flex flex-col items-center gap-[85px]">
          <img className="h-[435px] w-[460px]" src={Gift} alt="text icon" />
          <div className="flex flex-col gap-[20px]">
            <span className="text-18-semi text-darkGreen">Partager sur les réseaux sociaux</span>
            <div className="flex justify-between">
              {SOCIAL_MEDIA.map((socialMedia) => (
                <a href={socialMedia.link} target="_blank" rel="noreferrer" key={socialMedia.link}>
                  <img className="h-[44px] w-[44px]" src={socialMedia.logo} alt="text icon" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
