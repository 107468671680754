// import { getDownloadURL, ref } from "firebase/storage";
import { ChangeEvent, useState } from "react";

import { UserInfoModal } from "../modals/user-info-modal";

import TextIcon from "../../assets/text.svg";
import VideoIcon from "../../assets/video.svg";
import { useUser } from "../../services/user.context";

interface Props {
  tokenId?: string;
  price?: string;
}

export const ItemDonation = ({ tokenId, price }: Props) => {
  const [clickedButton, setClickedButton] = useState<"video" | "text">("video");
  const [video, setVideo] = useState<File>();
  const [message, setMessage] = useState<string>();
  const [isVideoPublic, setIsVideoPublic] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { fillUser } = useUser();

  const toggleCheckbox = () => {
    setIsVideoPublic(!isVideoPublic);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      // 10 Mo en octets
      const maxSize = 10 * 1024 * 1024;

      if (file.size > maxSize) {
        // Afficher une alerte ou gérer l'erreur comme vous le souhaitez
        alert("Le fichier est trop grand. La taille maximale est de 10 Mo.");
      } else {
        setVideo(file);
      }
    }
  };

  const handleClickedButton = (value: "video" | "text") => {
    setClickedButton(value);
  };

  const goNextStep = async () => {
    if (!price && !tokenId) return;

    fillUser({ public: isVideoPublic, price, tokenId });

    if (message) fillUser({ message });
    if (video) fillUser({ video });
    setShowModal(true);
  };

  return (
    <>
      {showModal && tokenId && <UserInfoModal closeModal={() => setShowModal(false)} />}
      <div className="flex h-fit w-fit flex-col gap-[12px]">
        <span className="flex text-24-semi text-white">Personnalisez votre don</span>
        <div className="flex h-fit w-[100%] rounded-[10px] border border-white p-[5px]">
          <button
            className={`flex w-1/2 items-center justify-center gap-[13px] rounded-[6px] py-[15px] ${
              clickedButton === "video" ? "bg-green" : ""
            }`}
            onClick={() => handleClickedButton("video")}
          >
            <img className="h-[19px] w-[32px]" src={VideoIcon} alt="video icon" />
            <span className="text-16-semi text-white">Video/photo</span>
          </button>
          <button
            className={`flex w-1/2 items-center justify-center gap-[13px] rounded-[6px] py-[15px] ${
              clickedButton === "text" ? "bg-green" : ""
            }`}
            onClick={() => handleClickedButton("text")}
          >
            <img className="h-[25px] w-[25px]" src={TextIcon} alt="text icon" />
            <span className="text-16-semi text-white">Text</span>
          </button>
        </div>
        <div className="flex h-[400px] w-full rounded-[6px]">
          {clickedButton === "text" ? (
            <textarea
              value={message || ""}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Votre message..."
              className="h-full w-full rounded-[6px] bg-transparent/20 p-[10px] text-white backdrop-blur-[25px] placeholder:text-white"
            />
          ) : (
            <div className="group relative flex h-full w-full items-center justify-center rounded-[6px] bg-transparent/50 text-white backdrop-blur-[25px]">
              <>
                {video ? (
                  <>
                    <video
                      controls
                      className="flex h-full w-full max-w-[610px] rounded-[6px] object-contain"
                      src={URL.createObjectURL(video)}
                    />
                    <button
                      onClick={() => setVideo(undefined)}
                      className="absolute bottom-[50px] left-[152px] cursor-pointer rounded-[6px] bg-green px-[70px] py-[15px] text-16-semi text-white opacity-0 group-hover:opacity-100"
                    >
                      Supprimer une vidéo
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleFileChange}
                      id="file-upload"
                      className="hidden"
                    />
                    <label
                      htmlFor="file-upload"
                      className="absolute bottom-[50px] cursor-pointer rounded-[6px] bg-green px-[70px] py-[15px] text-16-semi text-white group-hover:opacity-100"
                    >
                      Téléverser une vidéo
                    </label>
                  </>
                )}
              </>
            </div>
          )}
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="Checkbox" onChange={toggleCheckbox} checked={isVideoPublic} className="mr-2" />
          <label className="text-14-reg text-darkGreen2">
            J’autorise les Secours Populaires à utiliser ma vidéo à des fins promotionnelles
          </label>
        </div>
        <div className="flex justify-between gap-3">
          <button
            className="w-1/2 rounded-[10px] bg-lightGray4 px-[25px] py-[15px] text-16-semi text-darkGreen"
            onClick={() => {
              setVideo(undefined);
              setMessage(undefined);
              goNextStep();
            }}
          >
            Passez cette étape
          </button>
          <button
            className="w-1/2 rounded-[10px] bg-green px-[25px] py-[15px] text-16-semi text-white disabled:bg-lightGray4 disabled:text-gray"
            disabled={!video && !message}
            onClick={goNextStep}
          >
            Suivant
          </button>
        </div>
      </div>
    </>
  );
};
