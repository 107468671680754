import { useState } from "react";

import { Card } from "../ui/card";

import Filter from "../../assets/filter.svg";
import { Item } from "../../constants/items.constants";

interface Props {
  cards: Item[];
}

export const CardContainer = ({ cards }: Props) => {
  const [selectedAmount, setSelectedAmount] = useState<string | undefined | null>(null);

  const handleFilterClick = (amount: string | undefined | null) => {
    setSelectedAmount(amount);
  };

  const filteredCards = selectedAmount !== null ? cards.filter((card) => card.price === selectedAmount) : cards;

  const pillClassName =
    "flex px-[5px] rounded-full py-[10px] text-14-med text-darkGreen2 hover:filter hover:bg-transparent/50 xl:px-[20px]";

  const pillActiveClassName = "bg-green !text-white pointer-events-none";

  return (
    <div className="flex min-h-[1610px] w-fit flex-col justify-start gap-[40px] bg-transparent/20 px-[20px] pb-[151px] pt-[20px] backdrop-blur-[25px] xl:px-[120px] xl:pt-[143px]">
      <div className="flex w-full flex-col justify-between xl:flex-row">
        <div className="flex items-center gap-[5px] xl:gap-[12px]">
          <button
            className={`${pillClassName} ${selectedAmount === null && pillActiveClassName}`}
            onClick={() => handleFilterClick(null)}
          >
            Toutes les catégories
          </button>
          <button
            className={`${pillClassName} ${selectedAmount === "50" && pillActiveClassName}`}
            onClick={() => handleFilterClick("50")}
          >
            50 €
          </button>
          <button
            className={`${pillClassName} ${selectedAmount === "100" && pillActiveClassName}`}
            onClick={() => handleFilterClick("100")}
          >
            100 €
          </button>
          <button
            className={`${pillClassName} ${selectedAmount === "200" && pillActiveClassName}`}
            onClick={() => handleFilterClick("200")}
          >
            200 €
          </button>
          <button
            className={`${pillClassName} ${selectedAmount === "500" && pillActiveClassName}`}
            onClick={() => handleFilterClick("500")}
          >
            500 €
          </button>
          <button
            className={`${pillClassName} ${selectedAmount === undefined && pillActiveClassName}`}
            onClick={() => handleFilterClick(undefined)}
          >
            Montant libre
          </button>
        </div>
        <div className="hidden gap-[12px] xl:flex">
          <div className="flex items-center gap-[10px] px-[20px] py-[10px]">
            <img className="h-[24px] w-[24px]" src={Filter} />
            <span className="text-14-med text-darkGreen2">Filtres</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-10 xl:grid xl:grid-cols-4">
        {filteredCards.map((card) => (
          <Card key={card.id} item={card} />
        ))}
      </div>
    </div>
  );
};
