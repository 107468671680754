import { useNavigate } from "react-router-dom";

import EtherLogo from "../../assets/ethereum.svg";
import { Item } from "../../constants/items.constants";

interface Props {
  item: Item;
  variant?: "home" | "collection";
}

export const Card = ({ item, variant = "home" }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={`group flex h-fit max-w-[260px] cursor-pointer flex-col ${
        variant === "home" ? "rounded-[13px] bg-white p-[11px]" : "bg-lightGray3 pb-[28px]"
      } transition-all duration-200 ease-in hover:-translate-y-1 hover:shadow-sm`}
      onClick={() => navigate(`/item/${item.id}`)}
    >
      <div className="flex overflow-hidden rounded-[7px]">
        <img
          className={
            variant === "home"
              ? "w-[248px] transition duration-500 ease-in-out group-hover:scale-105"
              : "w-[307px] transition duration-500 ease-in-out group-hover:scale-105"
          }
          src={item.picture}
        />
      </div>
      <div className="flex flex-col gap-[11px] px-[12px] pt-[22px]">
        {variant === "collection" ? (
          <>
            <span className="text-20-semi text-dark">{item.title}</span>
            <div className="flex justify-between">
              <span className="text-16-reg text-lightGray">Price</span>
              <span className="text-16-reg text-lightGray">{item.price ? `${item.price} €` : "Prix libre"}</span>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-[11.5px] pb-[20px]">
            <span className="text-20-semi text-darkGreen2">{item.title}</span>
            <div className="flex justify-end gap-[5.5px]">
              <img className="h-[15px] w-[9px]" src={EtherLogo} />
              <span className="text-12-med text-flashyGreen">{item.price ? `${item.price} €` : "Prix libre"}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
