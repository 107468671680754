import { ItemInfos } from "../../components/item/item-infos";
import { Card } from "../../components/ui/card";
import { ITEMS } from "../../constants/items.constants";

export const ItemPage = () => {
  const itemsCollection = ITEMS.slice(0, 4);

  return (
    <div className="relative flex h-fit w-full flex-col items-center gap-[75px] pb-[105px] pt-[70px]">
      <div className="absolute left-0 top-0 z-0 flex h-full w-full flex-col">
        <div className="z-0 flex h-[1080px] w-full bg-background-top bg-cover bg-center" />
      </div>
      <div className="z-10 flex w-fit flex-col gap-[70px]">
        <div className="flex flex-col gap-[33px]">
          <span className="text-heading3 text-white xl:text-heading1">Noël Vert</span>
          <ItemInfos />
        </div>
        <span className="flex text-heading3 text-darkGreen">Dans la même collection</span>
        <div className="flex w-full flex-col items-center justify-center xl:flex-row xl:justify-between">
          {itemsCollection.map((card) => (
            <Card variant="collection" key={card.id} item={card} />
          ))}
        </div>
      </div>
    </div>
  );
};
