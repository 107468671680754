import { useState } from "react";

import { SliderCard } from "./slider-card";

import { Item } from "../../constants/items.constants";

interface Props {
  items: Item[];
}

export const Slider = ({ items }: Props) => {
  const [orderedItems, setOrderedItems] = useState(items);

  const handleCardClick = (index: number) => {
    const clickedItem = orderedItems.splice(0, index + 1);
    setOrderedItems([...orderedItems, ...clickedItem]);
  };

  return (
    <div className="relative flex h-fit w-[470px]">
      <div className="absolute right-0 top-[50px]" onClick={() => handleCardClick(1)}>
        <SliderCard size="small" item={orderedItems[2]} />
      </div>
      <div className="absolute right-[33px] top-[25px]" onClick={() => handleCardClick(0)}>
        <SliderCard size="medium" item={orderedItems[1]} />
      </div>
      <div className="absolute right-[67px] top-0">
        <SliderCard size="large" item={orderedItems[0]} />
      </div>
    </div>
  );
};
