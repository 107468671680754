import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ItemDonation } from "./item-donation";

import { ITEMS } from "../../constants/items.constants";

const MINIMUM_DONATION_AMOUNT = 50;

export const ItemInfos = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  if (id === undefined) {
    navigate("/");
  }

  const item = ITEMS[parseInt(id ?? "1") - 1];

  const [donationAmount, setDonationAmount] = useState<string>();
  const [showNextStep, setShowNextStep] = useState(false);

  const handleDonate = () => {
    if (!isPriceValid()) return;

    setShowNextStep(true);
  };

  const isPriceValid = () => {
    if (!item.price && !donationAmount) return false;
    if (donationAmount && parseFloat(donationAmount) < MINIMUM_DONATION_AMOUNT) return false;
    return true;
  };

  return (
    <div className="flex h-fit w-full flex-col px-[10px] xl:flex-row">
      <img className="flex h-auto max-w-[100%] rounded-[20px] xl:mr-[24px] xl:max-w-[636px]" src={item.picture} />
      {showNextStep ? (
        <ItemDonation tokenId={id} price={donationAmount ?? item.price} />
      ) : (
        <div className="flex h-fit w-fit flex-col gap-[60px]">
          <div className="flex flex-col gap-[10px]">
            <span className="text-heading3 text-white">{item.title}</span>
          </div>
          <div className="flex flex-col gap-[30px]">
            <div className="flex h-fit w-fit bg-transparent/20 px-[20px] py-[72px] backdrop-blur-[25px] xl:w-[565px]">
              <p className="whitespace-pre-wrap break-words text-16-reg xl:break-normal">{item.description}</p>
            </div>
            <div className="flex flex-col gap-[20px]">
              {!item.price && (
                <div className="flex flex-col gap-[12px]">
                  <span className="text-16-semi text-darkGreen">Entrer le montant du don*</span>
                  <input
                    className="w-full rounded-[10px] border border-darkGreen bg-transparent/20 px-[18px] py-[13px]"
                    type="number"
                    value={donationAmount}
                    onChange={(e) => {
                      setDonationAmount(e.target.value);
                    }}
                    min={50}
                    placeholder={`Minimum ${MINIMUM_DONATION_AMOUNT} €`}
                  />
                </div>
              )}
              <button
                className="w-full justify-center rounded-[7px] bg-green pb-[15px] pt-[11px] text-white disabled:bg-lightGray"
                onClick={handleDonate}
                disabled={!isPriceValid()}
              >
                {isPriceValid()
                  ? `Faire un don de ${donationAmount ?? item.price} €`
                  : `Entrer un montant supérieur à ${MINIMUM_DONATION_AMOUNT} €`}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
