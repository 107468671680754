export const PaymentErrorPage = () => {
  return (
    <div className="relative flex flex-col px-[26px] pb-[48px] pt-[20px]">
      <div className="flex flex-col gap-[103px]">
        <div className="flex flex-col gap-[22px]">
          <span className="text-32-semi text-darkGreen">Votre payment a échoué !</span>
          <span className="text-16-reg text-blue">Veuillez réessayer ou contacter le support</span>
        </div>
      </div>
    </div>
  );
};
