import { User } from "./user.context";

const userToFormData = (json: User) => {
  const formData = new FormData();

  Object.entries(json).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export const getPaymentUrl = async (user: User) => {
  const response = await fetch("/api/create-order", {
    method: "POST",
    body: userToFormData(user),
  });

  const { url } = await response.json();

  return url;
};
