import { ReactNode } from "react";

import { Footer } from "../footer/footer";
import { Navbar } from "../navbar/navbar";

type Props = {
  children?: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <div className="flex h-fit w-full flex-col items-center justify-center">
      <Navbar />
      <main className="flex h-fit w-full flex-col items-center">{children}</main>
      <Footer />
    </div>
  );
};
