import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Layout } from "./components/layout/layout";
import { HomePage } from "./pages/home/home-page";
import { ItemPage } from "./pages/home/item-page";
import { PaymentErrorPage } from "./pages/payment-error-page";
import { ThankYouPage } from "./pages/thank-you-page";
import { UserProvider } from "./services/user.context";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <UserProvider>
      {/* <MinWidthWarning /> */}
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/item/:id" element={<ItemPage />} />
          <Route path="/success" element={<ThankYouPage />} />
          <Route path="/cancel" element={<PaymentErrorPage />} />
        </Routes>
      </Layout>
    </UserProvider>
  );
}

export default App;
