import { useState } from "react";

import CloseIcon from "../../assets/close.svg";
import { COUNTRIES } from "../../constants/countries.constants";
import { getPaymentUrl } from "../../services/api.services";
import { useUser } from "../../services/user.context";
import { isEmailValid } from "../../utils/formatter";

interface Props {
  closeModal: () => void;
}

export const UserInfoModal = ({ closeModal }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>();

  const INPUT_CLASSNAME = "border-darkGreen w-full rounded-[10px] border px-[18px] py-[13px]";

  const { user, fillUser } = useUser();

  const handleSubmit = async () => {
    if (!isEmailValid(user.emailAddress)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    if (!user?.fullName || !user.emailAddress || !user.addressLine1 || !user.zipCode || !user.city || !user.country) {
      setErrorMessage("Please complete all fields");
      return;
    }

    if (!user.price) {
      setErrorMessage("No price for this item. Please refresh the page");
      return;
    }

    if (!user.tokenId) {
      setErrorMessage("No tokenId for this item. Please refresh the page");
      return;
    }

    setErrorMessage("");

    try {
      setLoading(true);
      if (user) setUrl(await getPaymentUrl(!user.addressLine2 ? { ...user, addressLine2: "" } : user));
    } catch {
      setErrorMessage("Error while processing your request");
    } finally {
      setLoading(false);
    }
  };

  const buttonLabel = loading ? "Loading ..." : url ? "Acheter mon NFT" : "Continuer";

  return (
    <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-transparent/20 backdrop-blur-[15px]">
      <div className="relative flex h-fit w-[438px] flex-col items-center justify-center gap-[40px] rounded-[20px] bg-white px-[26px] pb-[48px] pt-[20px]">
        {!loading && (
          <img
            className="absolute right-[10px] top-[13px] h-[25px] w-[25px] cursor-pointer"
            src={CloseIcon}
            alt="text icon"
            onClick={closeModal}
          />
        )}
        <span className="py-5 text-32-semi">{url ? "Procéder au paiement" : "Vos informations"}</span>
        {url ? (
          <span>Informations envoyées !</span>
        ) : (
          <div className="flex w-full flex-col gap-[20px]">
            <span className="text-12-med text-red">{errorMessage}</span>
            <input
              className={INPUT_CLASSNAME}
              type="text"
              value={user.fullName}
              onChange={(e) => fillUser({ fullName: e.target.value })}
              placeholder="Nom complet"
            />
            <input
              className={INPUT_CLASSNAME}
              type="email"
              value={user.emailAddress}
              onChange={(e) => fillUser({ emailAddress: e.target.value.toLowerCase() })}
              placeholder="E-mail"
            />
            <select
              className={INPUT_CLASSNAME}
              placeholder="Pays/Région"
              value={user.country}
              onChange={(e) => fillUser({ country: e.target.value })}
            >
              {Object.entries(COUNTRIES).map(([code, nom]) => (
                <option key={code} value={code}>
                  {nom}
                </option>
              ))}
            </select>
            <input
              className={INPUT_CLASSNAME}
              type="text"
              value={user.addressLine1}
              onChange={(e) => fillUser({ addressLine1: e.target.value })}
              placeholder="Adresse ligne 1 : rue, numéro, appartement"
            />
            <input
              className={INPUT_CLASSNAME}
              type="text"
              value={user.addressLine2}
              onChange={(e) => fillUser({ addressLine2: e.target.value })}
              placeholder="Adresse ligne 2 : rue, numéro, appartement"
            />
            <input
              className={INPUT_CLASSNAME}
              type="text"
              minLength={5}
              maxLength={5}
              value={user.zipCode}
              onChange={(e) => fillUser({ zipCode: e.target.value })}
              placeholder="Code postal"
            />
            <input
              className={INPUT_CLASSNAME}
              type="text"
              value={user.city}
              onChange={(e) => fillUser({ city: e.target.value })}
              placeholder="Ville"
            />
          </div>
        )}
        <button
          className="w-full justify-center rounded-[7px] bg-green pb-[15px] pt-[11px] text-white disabled:cursor-not-allowed disabled:opacity-50"
          onClick={url ? () => window.open(url) : handleSubmit}
          disabled={loading}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};
