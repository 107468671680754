import { Link } from "react-router-dom";

export const Navbar = () => {
  const LINK_CLASSNAME = "text-18-semi hover:text-lightGreen transition-all hover:text-green duration-100 ease-in-out";

  return (
    <div className="mx-auto flex h-[100px] w-full items-center justify-around bg-white py-3">
      <a className="h-[30px] xl:h-full" href="https://www.secourspopparis.org/">
        <img
          className="h-full"
          src="https://peresnoelverts.secourspopparis.org/wp-content/uploads/2023/11/logo-sp-color.png"
          alt="logo"
        />
      </a>
      <div className="flex gap-[31px] text-dark">
        <Link to="/" className={LINK_CLASSNAME}>
          Collection
        </Link>
        <a href="mailto:marketing@metacard.gift" className={LINK_CLASSNAME}>
          Contact
        </a>
      </div>
    </div>
  );
};
