import InstagramLogo from "../../assets/Instagram.svg";
import LinkedinLogo from "../../assets/Linkedin.svg";
import Logo from "../../assets/logo.png";
import TwitterLogo from "../../assets/Twitter.svg";
import YoutubeLogo from "../../assets/Youtube.svg";

export const Footer = () => {
  return (
    <div className="flex h-fit w-full bg-blue px-[60px] py-[85px] xl:px-[160px]">
      <div className="flex h-fit w-full flex-col justify-between p-[32px] xl:h-[460px] xl:flex-row">
        <a href="http://secourspopparis.org/" target="_blank" rel="noreferrer">
          <img className="h-[113px] w-auto" src={Logo} alt="logo" />
        </a>
        <div className="flex flex-col gap-[102px] xl:flex-row">
          <div className="flex flex-col gap-[32px]">
            <span className="text-32-semi text-lightBlue">Support</span>
            <div className="flex flex-col gap-[20px]">
              <a className="text-20-semi text-white" target="_blank" href="/assets/cgu.pdf">
                CGU
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-[32px]">
            <span className="text-32-semi text-lightBlue">Social media</span>
            <div className="flex flex-col gap-[40px] xl:flex-row">
              <a href="https://www.linkedin.com/company/secours-populaire-de-paris/" target="_blank" rel="noreferrer">
                <img className="h-[24px] w-[24px]" src={LinkedinLogo} alt="Linkedinlogo" />
              </a>
              <a href="https://www.youtube.com/channel/UCH-8y0hLILvebYFkv0UMMNg" target="_blank" rel="noreferrer">
                <img className="h-[24px] w-[28px]" src={YoutubeLogo} alt="Yotubelogo" />
              </a>
              <a href="https://www.instagram.com/secourspopparis/" target="_blank" rel="noreferrer">
                <img className="h-[24px] w-[24px]" src={InstagramLogo} alt="Instagramlogo" />
              </a>
              <a href="https://twitter.com/SecourspopParis" target="_blank" rel="noreferrer">
                <img className="h-[24px] w-[28px]" src={TwitterLogo} alt="Twitterlogo" />
              </a>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
